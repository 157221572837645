.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #fff;
}

.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #274760;
}

.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}

.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}

.cs_video_open {
  cursor: pointer;
}

.cs_hero.cs_style_1 {

  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}

.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}

.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}

.cs_rating {
  i {
    display: flex;
  }
}

.cs_shape_wrap .cs_shape_2 {
  top: -3%;
}

.cs_cs_slider_navigation_1 {

  .cs_slider_next,
  .cs_slider_prev {
    transition: all 0.4s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
    transform: translateY(-50%);
    background-color: #fff;
    width: 70px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    position: absolute;
    top: 50%;
    display: flex !important;
    z-index: 1;

    @media (min-width: 991px) {
      opacity: 0;
    }
  }

  .cs_slider_next {
    right: -35px;
  }

  .cs_slider_prev {
    left: -35px;
  }

  &:hover {

    .cs_slider_next,
    .cs_slider_prev {
      opacity: 1;
    }
  }
}

.cs_cs_slider_navigation_2 {

  .cs_slider_next,
  .cs_slider_prev {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: absolute;
    display: flex !important;
    bottom: 0;
  }

  .cs_slider_next {
    right: 0;
  }

  .cs_slider_prev {
    right: 52px;
  }
}

.cs_cs_slider_navigation_3 {

  .cs_slider_next,
  .cs_slider_prev {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex !important;
    position: absolute;
    top: -213px;

    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
      top: -188px;
    }

    @media (max-width: 991px) {
      display: none !important;
    }

    img {
      transition: inherit;
    }

    &:hover {
      background-color: $accent;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .cs_slider_next {
    right: 0;
  }

  .cs_slider_prev {
    right: 115px;

    @media (max-width: 1700px) {
      right: 100px;
    }
  }

  .slick-dots {
    display: none !important;

    @media (max-width: 991px) {
      display: flex !important;
    }
  }
}

.cs_related_doctor {
  padding-bottom: 90px;

  @media (max-width: 400px) {
    padding-bottom: 75px;
  }

  .slick-dots {
    bottom: 15px;
  }
}

.cs_gallery_grid_1 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);

    .cs_grid_item {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .cs_section_heading.cs_style_1 {
    min-height: 263px;

    @media (max-width: 1700px) {
      min-height: 166px;
    }

    @media (max-width: 991px) {
      min-height: initial;
    }
  }

  .cs_grid_item {
    &:first-child {
      grid-column: span 2;
    }

    @media (min-width: 992px) {
      &:nth-child(6) {
        grid-column: span 2;
      }
    }

    &:nth-child(5) {
      .cs_portfolio.cs_style_1 {
        height: 767px;
        margin-top: -338px;

        @media (max-width: 1700px) {
          height: 590px;
          margin-top: -240px;
        }

        @media (max-width: 991px) {
          margin-top: 0;
          height: 400px;
        }
      }

      @media (max-width: 991px) {
        grid-column: span 2;
      }

      @media (max-width: 767px) {
        grid-column: initial;
      }
    }
  }

  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
}

.cs_gallery_grid_2 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px 0px;
  }

  .cs_grid_item {
    @media (min-width: 768px) {
      &:first-child {
        grid-column: span 2;
      }
    }

    @media (min-width: 992px) {
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }

  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }

  @media (max-width: 420px) {
    .cs_portfolio.cs_style_1 {
      height: 300px;
    }
  }
}

.cs_portfolio.cs_style_1 .cs_portfolio_img>div>img {
  cursor: zoom-in;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.cs_team_grid {
  display: grid;
  grid-gap: 50px 25px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 25px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 25px;
  }

  &.cs_list_view_wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  &.cs_grid_view_wrap {
    .cs_team.cs_style_1.cs_type_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.cs_isotop_filter.cs_style1 {
  text-transform: capitalize;
}

.cs_pagination_box {

  .cs_pagination_item,
  .cs_pagination_arrow {
    border: none;

    &:hover {
      color: $accent;
    }
  }

  .cs_pagination_arrow {
    background-color: transparent;
  }
}

.form-check .form-check-input {
  border-color: #636363;
}

.cs_related_doctor .slick-dots {
  display: flex !important;
}

.react-datepicker-wrapper {
  display: block;
}

.cs_blog_grid_1 {
  display: grid;
  grid-gap: 20px 130px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1700px) {
    grid-gap: 20px 80px;
  }

  @media (max-width: 1400px) {
    grid-gap: 20px;
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 992px) {
    >* {
      grid-column: span 2;

      &:first-child {
        grid-row: span 3;
      }
    }
  }
}

.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}

.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active+ul {
  display: block;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.rounded-circle {
  border-radius: 28px !important;
}


@media (max-width: 1200px) {
  .cs_mega_menu {
    ul {
      background-color: #ecf7ff;
      overflow: auto;
      padding: 22px 0px;
      margin-bottom: 24px;
      li {
        width: 100%;
        float: left;
        margin-bottom: 16px;
        a {

          padding: 10px !important;
          display: flex !important;
          gap:24px;
          align-items: flex-start;
          img {
            width: 30px;
          }
          div
          {
            
            width: auto;
            label
            { 
                color: #000;
            }
            p
            {
              font-size: 12px;
              padding-top: 5px;
            }
          }
        }
      }

    }
  }
}

@media (max-width: 575px) {
.cs_style_1
{
  .cs_hero_img
  {
    margin-bottom: 0px !important;
  }
}
.cs_fs_94
{
  font-size: 40px;
}
.responsive-center
{
  text-align: center;
  .cs_iconbox.cs_style_12
  {
    align-items: center;
  }
  .cs_iconbox_right
  {
    text-align: center !important;
  }
  .cs_iconbox.cs_style_3
  {
    align-items: center;
  }
}


.cs_iconbox.cs_style_custom h2
{
  font-size: 14px;
  text-align: center;

}
}