.cs_cta.cs_style_1 {
  padding: 125px 10% 140px 10%;
  @media (max-width: 1199px) {
    padding: 95px 30px 100px 45%;
  }
  @media (max-width: 767px) {
    padding: 125px 10% 140px 10%;
  }
  @media (max-width: 575px) {
    padding: 50px 4% 50px 4%;
  }
  .cs_cta_title,
  .cs_cta_subtitle {
    margin-bottom: 38px;
  }

}
